import './style.scss';
import {ReactComponent as Logo} from "../../asset/svg/ytLogo.svg";
import {ReactComponent as FSI} from "../../asset/svg/fsiLogo.svg";
import {ReactComponent as Phone} from "../../asset/svg/phone.svg";
import {ReactComponent as Email} from "../../asset/svg/email.svg";
import {ReactComponent as Telegram} from "../../asset/svg/telegram.svg";
import {ReactComponent as Whatsapp} from "../../asset/svg/whatsapp.svg";
import {ReactComponent as MTCLogo} from "../../asset/svg/mtcLogo.svg";
import Scolkovo from '../../asset/images/skolkovo.png';
import {menuPoints} from "../../asset/data/menu";

const Footer = props => {
    return <footer>
        <div className={'footer__content'}>
            <div className={'footer__content__logoLine'}>
                <a className={'pointer'} href={'#'}><Logo/></a>
            </div>
            <div className={'footer__content__data'}>
                <div>
                    <a className={'pointer'} href={'#'}><Logo/></a>
                </div>
                <div className={'footer__content__data__first'}>
                    {menuPoints.map((item, key) => {
                        return <a key={key} href={item.link}>{item.title}</a>
                    })}
                </div>
                <div className={'footer__content__data__second'}>
                    <span>Свяжитесь с нами</span>
                    <a href={'tel:+79996200146'} target={'__blank'}><Phone className={'size-18'}/> <span>+7 (999) 620-01-46</span></a>
                    <a href={'mailto:+79996200146'} target={'__blank'}><Email className={'size-18'}/>
                        <span>vmv@yourtar.ru</span></a>
                    <a href={'https://t.me/ipvmv94'} target={'__blank'}><Telegram className={'size-18'}/>
                        <span>Telegram</span></a>
                    <a href={'https://wa.me/79996200146'} target={'__blank'}><Whatsapp className={'size-18'}/>
                        <span>WhatsApp</span></a>
                </div>
                <div className={'footer__content__data__second'}></div>
                <div className={'footer__content__data__logos'}>
                    <a href={'https://fasie.ru'} target={'_blank'}>
                        <div>
                            <FSI/>
                            <span>Проект поддержан Фондом содействия инновациям в рамках программы «Студенческий стартап» федерального проекта «Платформа университетского технологического предпринимательства»</span>
                        </div>
                    </a>
                    <div>
                        <img src={Scolkovo} alt={'Сколково'}/>
                        <MTCLogo/>
                    </div>
                </div>
                <div className={'footer__content__separator'}/>
                <div className={'footer__content__copyright'}>
                    <div></div>
                    <div className={'footer__content__copyright__text'}>
                        ООО «ЁРТАР» © Все права защищены / 2024
                    </div>
                    <div className={'footer__content__copyright__docs'}>
                        <span>Публичная оферта</span>
                        <span>Политика конфиденциальности</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer;
